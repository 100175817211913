import throttle from 'lodash/throttle';
import defaults from 'lodash/defaults';
import amplitude from 'amplitude-js';
import * as Sentry from '@sentry/browser';
import { Builder } from '@builder.io/sdk';
import { getReferrerCookie, getCookie, setCookie } from './init-referrer-cookie';

function randomHash(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const isDev = ['localhost', 'local.builder.io', 'local-qa.builder.io'].includes(
  window.location.hostname
);

const noTrack = Builder.isPreviewing || Builder.isEditing;

// TODO: Move these to shared configs
const AMPLITUDE_KEY = '2532be1b0436a18cb938b21fc7fa9faf';
const SENTRY_CLIENT_ID = 'b243dcca3929490989f3bbc6f248dd21';

const amplitudeInstance = amplitude.getInstance();
amplitudeInstance.init(AMPLITUDE_KEY);
if (!isDev) {
  Sentry.init({
    dsn: `https://${SENTRY_CLIENT_ID}@sentry.io/258943`,
  });
}

const userId = getCookie('builder.userId');
let visitorId = getCookie('b_visitor');

if (!visitorId) {
  visitorId = randomHash(32);
  setCookie('b_visitor', visitorId, 400);
}

if (userId) {
  amplitudeInstance.setUserId(userId);
  const { FS } = window as any;
  if (FS) {
    FS.identify(userId);
  }
}

addEventListener('error', err => {
  if (isDev) {
    return;
  }
  track('error', {
    message: err.message,
    error: err.error && err.error.message,
    stack: err.error && err.error.stack,
    errorLocation: `${err.filename || (err as any).source}:${err.lineno}:${err.colno}`,
  });
});

export const fastClone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

const DEBUG_TRACKING = isDev;

interface Event {
  name: string;
  data: any;
}
let eventQueue: Event[] = [];

// TODO: share with app somehow
const sessionId = Math.random().toString(36).split('.')[1];

export function track(name: string, data = {} as any) {
  if (isDev || noTrack) {
    if (DEBUG_TRACKING) {
      console.debug('track:', name, data);
    }
    return false;
  }
  data = fastClone(data);

  const { Intercom, gtag, FS } = window as any;

  // Add these to top level in bigquery (?)
  data = defaults(data, {
    userId,
    visitorId,
    url: location.href,
    app: 'site',
    host: location.hostname,
    // TODO: get user data from firebase for tracking
    sessionId,
    // TODO
    // organizationId: appState.user.organization && appState.user.organization.value.id,
    userLoggedIn: Boolean(userId),
    initialReferrer: getReferrerCookie(),
    timestamp: Date.now(),
    date: new Date().toUTCString(),
    sessionUrl:
      FS && typeof FS.getCurrentSessionURL === 'function' && FS.getCurrentSessionURL(true),
  });

  trackInternal(name, data);

  amplitudeInstance.logEvent(name, data);

  if (FS && typeof FS.event === 'function') {
    FS.event(name, fastClone(data));
  }

  const _hsq = ((window as any)._hsq = (window as any)._hsq || []);

  if (name === 'pageView') {
    _hsq.push(['trackPageView']);
  } else {
    _hsq.push(['trackEvent', { id: name }]);
  }

  if (typeof Intercom === 'function') {
    Intercom('trackEvent', name, data);
    if (name === 'pageView') {
      Intercom('update');
    }
  }
  if (typeof gtag === 'function') {
    gtag('send', {
      hitType: 'event',
      eventCategory: 'Site',
      eventAction: name,
    });
  }

  return true;
}

export function trackInternal(name: string, data?: any) {
  if (isDev) {
    return false;
  }
  eventQueue.push({ name, data: flattenObject(data || {}) });
  throttledClearInternalQueue();
  return true;
}

const throttledClearInternalQueue = throttle(
  () => {
    if (!eventQueue.length) {
      return;
    }
    const queue = eventQueue.slice();
    eventQueue = [];
    return fetch('https://builder.io/api/v1/event', {
      method: 'POST',
      body: JSON.stringify({ events: queue }),
      headers: {
        'content-type': 'application/json; charset=utf-8',
      },
    });
  },
  500,
  { leading: false, trailing: true }
);

function flattenObject(
  obj: any,
  _current?: any,
  _returnObject: any = {}
): { [key: string]: string } {
  for (const key in obj) {
    const value = obj[key];
    const newKey = _current ? `${_current}.${key}` : key;
    if (value && typeof value === 'object') {
      flattenObject(value, newKey, _returnObject);
    } else {
      _returnObject[newKey] = value;
    }
  }

  return _returnObject;
}
